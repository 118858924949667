'use client'
import React, { useEffect } from 'react'
import ReactFullStory, { FullStoryAPI } from 'react-fullstory'
import useAuth from '@crystal-eyes/hooks/useAuth'

export default function FullStory({ }: { unauthed?: boolean }) {
  const { data: auth } = useAuth()

  useEffect(() => {
    if (auth?.jwtPayload && !auth.jwtPayload.impersonated_by) {
      const jwtPayload = auth.jwtPayload

      try {
        FullStoryAPI('identify', jwtPayload.sub?.split(':')[1], {
          displayName: jwtPayload.name,
        })
      } catch (e) {}
    }
  }, [auth])

  return <ReactFullStory org="5WWQV" />
}
